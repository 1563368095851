import React from 'react'
import { graphql } from 'gatsby'

import SEO from '../components/SEO/SEO'

import DynamicZone from '../components/DynamicZone/DynamicZone'

const homePage = ( { data: { strapi: { artTrail } }, location } ) => {

	return (
		<>
			<SEO title={ `Homepage ${ artTrail.trailYear }` } description={ artTrail.seoDescription } pathname={ location.pathname } />

			{ artTrail.content &&
				<DynamicZone components={ artTrail.content } />
			}

		</>
	)
}

export default homePage

export const query = graphql`query homePage($id: ID!) {
	strapi {
		artTrail(id: $id) {
			id
			trailYear
			seoDescription
			content {
				... on StrapiQuery_ComponentCommonHeroCarousel {
					id
					heroFocus
					heroImages {
						url
						imageFile {
							childImageSharp {
								fluid(maxWidth: 1800) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
				... on StrapiQuery_ComponentCommonCallToActionBanner {
					id
					title
					description
					link
					linkLabel
					isExternalLink
					link2
					linkLabel2
					isExternalLink2
					link3
					linkLabel3
					isExternalLink3
					link4
					linkLabel4
					isExternalLink4
				}
				... on StrapiQuery_ComponentArtTrailTrailInfo {
					id
					trailInfoIntro
					trailInfoTitle
					trailInfoLinkUrl
					trailInfoLinkLabel
					trailInfoLinkExternal
					trailInfoImage {
						url
						imageFile {
							childImageSharp {
								fluid(maxWidth: 600) {
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
				... on StrapiQuery_ComponentCommonImage {
					id
					imageLinkUrl
					imageLinkLabel
					imageLinkExternal
					image {
						url
						imageFile {
							childImageSharp {
								fluid(maxWidth: 1000) {
									aspectRatio
									...GatsbyImageSharpFluid_withWebp
								}
							}
						}
					}
				}
				... on StrapiQuery_ComponentArticleTextBlock {
					id
					text
				}
				... on StrapiQuery_ComponentCommonVideo {
					videoTitle
					videoNumber
				}
			}
		}
	}
}
`